<template>
    <div>
        <div>
            <!-- <v-btn color="success"  >Filter</v-btn> -->
            <!-- <Popup  /> -->

            <v-container grid-list-xl>
                <v-layout row justify-space-between >
                    <v-flex md3>
                        <v-select
                            item-text="value"
                            item-value="key"
                            v-model="dashboard"
                            :items="dashboards"
                            @change="dashboardChanged"
                            label="Dashboard"
                            prepend-icon="dashboard"
                            single-line
                        ></v-select>
                    </v-flex>
                    <v-flex md2>
                        <v-checkbox
                            v-model="isDesign"
                            label="Design Mode"
                            @change="this.renderReport"
                        ></v-checkbox>
                    </v-flex>
                </v-layout> 
            </v-container>
            

            <div :ref="'embedReportContainer_' + 0" style="height:700px;width:100%;" v-if="!this.isDesign" ></div>
                
        </div>
        <grid-layout 
                v-if="isDesign"
                :layout.sync="layout"
                :col-num="12"
                :row-height="30"
                :is-draggable="true"
                :is-resizable="true"
                :is-mirrored="false"
                :vertical-compact="true"
                :margin="[10, 10]"
                :use-css-transforms="true"
                @layout-updated="layoutUpdatedEvent"
        >
    
            <grid-item v-for="item in layout" style="background-color:white"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :key="item.i"
                        >

                <div :ref="'embedContainer_' + item.i" style="min-height:100%;display:block;height:100%;" ></div>
                
            </grid-item>
        </grid-layout>
    </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
//import VueFriendlyIframe from 'vue-friendly-iframe';
import * as pbi from 'powerbi-client';
import axios from 'axios';
//import { async } from 'q';
import Popup from '../components/Popup'
import { async } from 'q';

let layout1 = [
        {"x":0,"y":0,"w":2,"h":4,"i":"0", "pageName":"ReportSection", "visualName":"32e6437bb0dde8010520" },
        {"x":2,"y":0,"w":2,"h":4,"i":"1", "pageName":"ReportSection", "visualName":"eddc71626a5369076951" },
        {"x":4,"y":0,"w":2,"h":4,"i":"2", "pageName":"ReportSection", "visualName":"de9e774292120988628c" },
        {"x":6,"y":0,"w":2,"h":4,"i":"3", "pageName":"ReportSection", "visualName":"e222b45d185e995dea6d" },
        {"x":8,"y":0,"w":2,"h":4,"i":"4", "pageName":"ReportSection", "visualName":"e4f75211cc50629c2d55" },
        {"x":10,"y":0,"w":2,"h":4,"i":"5", "pageName":"ReportSection", "visualName":"1a0aa44f0a67546c7b23" },
        {"x":0,"y":4,"w":12,"h":6,"i":"6",  "pageName":"ReportSection", "visualName":"d9d25c89cd57fce11261"}
    ];
let layout2 = [
        {"x":0,"y":0,"w":6,"h":6,"i":"0",  "pageName":"ReportSection263db08033d2dc55e93b", "visualName":"61541c1d73932a933ffb"},
        {"x":6,"y":0,"w":6,"h":6,"i":"1",  "pageName":"ReportSection263db08033d2dc55e93b", "visualName":"f07488ddb1f3427771de"},
        {"x":0,"y":6,"w":6,"h":6,"i":"2",  "pageName":"ReportSection263db08033d2dc55e93b", "visualName":"1b764189e5e9c8cca10c"},
        {"x":6,"y":6,"w":6,"h":6,"i":"3",  "pageName":"ReportSection263db08033d2dc55e93b", "visualName":"1143b2d8ceb2b1467c55"}
    ];
export default {
    components: {
           GridLayout: VueGridLayout.GridLayout,
           GridItem: VueGridLayout.GridItem,
           Popup
           //VueFriendlyIframe: VueFriendlyIframe
    },
    computed: {
        //tileId: function ()
    },
    data(){
      return {
          dashboards: [{key: 1, value: "Dashboard 1"}, {key: 2, value: "Dashboard 2"}],
          dashboard: 1,
          layout: layout1,
          embedToken: null,
          reportId: "1961b60f-ecc6-4749-bda8-c253f7a4d93c",
          groupId: "c988131f-3920-449f-bb7e-ea94ca753bb7",
          isDesign: false
      }
    },
    methods: {
        dashboardChanged: async function () {
            if(this.dashboard == 1){
                this.layout = layout1;
            } else {
                this.layout = layout2;
            }

            try{
                await this.renderReport();
            }
            catch(err){
            }
        },
        getEmbedToken: async function() {
            //console.log("----------before call bi service-----------")
            
            try {
            
                const result = await axios({
                    url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/bi/embed-token/' + this.reportId + '/' + this.groupId
                    , method: 'GET'
                });

                this.embedToken = result.data;
                //console.log(this.embedToken);
            }
            catch(err) {
                alert(err);
            }
        },
        layoutUpdatedEvent: function(newLayout){
            console.log("Updated layout: ", newLayout)
            //console.log(this.layout)
        },
        applyFilter: function(){

            if(true){
                console.log("------------ before applying filter ---------------")
                // Build the filter you want to use. For more information, See Constructing
                // Filters in https://github.com/Microsoft/PowerBI-JavaScript/wiki/Filters.
                const filter = {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: "Date",
                        column: "Months"
                    },
                    operator: "In",
                    values: ["Oct", "Nov", "Dec"]
                };

                // Get a reference to the embedded report HTML element
                let embedContainer = this.$refs["embedContainer_4"][0];

                // Get a reference to the embedded report.
                let visual = powerbi.get(embedContainer);

                // Set the filter for the report.
                // Pay attention that setFilters receives an array.
                visual.setFilters([filter])
                    .then(function () {
                        console.log("Report filter was set.");
                    })
                    .catch(function (errors) {
                        console.log(errors);
                    });
            }

        },
        calculateVisulaLayout: function (mode, offset) {
            let visualLayout = {};
            this.layout.forEach(tile => {
                visualLayout[tile.visualName] = { x: (tile.x * offset) + 5, y: (tile.y * (offset/2)) + 1, z: 1, width: tile.w * offset, height: tile.h * (offset/2), displayState: {mode: mode}}
            })

            return visualLayout;
        },
        renderReport: async function () {
            //if(!this.embedToken)
            await this.getEmbedToken();

            let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

            // Read embed URL from textbox
            let txtEmbedUrl = "https://app.powerbi.com/reportEmbed?reportId=" + this.reportId + "&groupId=" + this.groupId; // + "&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQifQ%3d%3d";
                            
            // Read report Id from textbox
            let txtReportId = this.reportId;

            if(this.isDesign) {
                this.layout.forEach( layoutItem => {

                    if(!layoutItem.pageName)
                        return;

                    console.log(layoutItem.i);

                    

                    // Read page name from textbox
                    let txtPageName = layoutItem.pageName;

                    // Read visual name from textbox
                    let txtVisualName = layoutItem.visualName;

                    

                    // Get models. models contains enums that can be used.
                    let models = pbi.models;

                    // Embed configuration used to describe the what and how to embed.
                    // This object is used when calling powerbi.embed.
                    // This also includes settings and options such as filters.
                    // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
                    let config= {
                        type: 'visual',
                        tokenType: models.TokenType.Embed,
                        accessToken: this.embedToken,
                        embedUrl: txtEmbedUrl,
                        id: txtReportId,
                        pageName: txtPageName,
                        visualName: txtVisualName
                    };


                    // Embed the report and display it within the div container.
                    let report = powerbi.embedNew(this.$refs["embedContainer_" + layoutItem.i][0], config);

                    // Report.off removes a given event handler if it exists.
                    report.off("loaded");

                    // Report.on will add an event handler which prints to Log window.
                    report.on("loaded", function(configuration) {
                        console.log("---------  loaded ---------------");
                        console.log(configuration);
                    });

                    

                    // Report.off removes a given event handler if it exists.
                    report.off("filtersApplied");

                    // Report.on will add an event handler which prints to Log window.
                    report.on("filtersApplied", function(configuration) {
                        console.log("---------  filtersApplied  ---------------");
                        console.log(configuration);
                    });

                    
                    // Report.off removes a given event handler if it exists.
                    report.off("dataSelected");

                    // Report.on will add an event handler which prints to Log window.
                    report.on("dataSelected", function(configuration) {
                        console.log("---------  dataSelected  ---------------");
                        console.log(configuration);
                    });


                    // Report.off removes a given event handler if it exists.
                    report.off("rendered");

                    // Report.on will add an event handler which prints to Log window.
                    report.on("rendered", function(rendered) {
                        console.log("---------- Rendered -------------");
                        console.log(rendered);

                        
                    });

                    report.on("error", function(event) {
                        console.log(event.detail);

                        report.off("error");
                    });
                })
            }

            //---------------------------------------------------
            let models = pbi.models;
            let reportConfig = {
                    type: 'report',
                    tokenType: models.TokenType.Embed,
                    accessToken: this.embedToken,
                    embedUrl: txtEmbedUrl,
                    id: txtReportId,
                    pageName: this.layout[0].pageName,
                    settings: {
                        filterPaneEnabled: false,
                        navContentPaneEnabled: false,
                        layoutType: models.LayoutType.Custom,
                        customLayout: {
                            pageSize: {
                                type: models.PageSizeType.Custom,
                                width: 1050,
                                height: 600
                            },
                            displayOption: models.DisplayOption.ActualSize,
                            pagesLayout: {
                                
                            }
                        }
                    }
                };
                
                if(!this.isDesign) {

                    reportConfig.settings.customLayout.pagesLayout[this.layout[0].pageName] = {
                                    defaultLayout: {
                                        displayState: {
                                            mode: models.VisualContainerDisplayMode.Hidden
                                        }
                                    },
                                    visualsLayout: {}
                                };


                
                    reportConfig.settings.customLayout.pagesLayout[this.layout[0].pageName].visualsLayout= this.calculateVisulaLayout(models.VisualContainerDisplayMode.Visible, 88);
                    console.log(reportConfig); 
                    
                    try{
                        // Embed the report and display it within the div container.
                        let reportLayout = powerbi.embedNew(this.$refs["embedReportContainer_0"], reportConfig);
                    } catch(err){
                    }
                    

                }
        }
    },
    created: function () {
        //console.log(this.calculateVisulaLayout({}, 100));
    },
    mounted: async function () {
        
        await this.renderReport();
    
    }
}
</script>

<style>
    
</style>
